// src/services/userService.js
import api from "./api";

const getUsersForAdmin = async (queryParams) => {
  const response = await api.get(`/admin-users`, { params: queryParams });
  return response.data;
};

const approveRejectMultipleUsers = async (userData) => {
  const response = await api.put(
    `/admin-users/approve-reject-multiple`,
    userData
  );
  return response.data;
};

const blockUnblockMultipleUsers = async (userData) => {
  const response = await api.put(
    `/admin-users/block-unblock-multiple`,
    userData
  );
  return response.data;
};

const deleteMultipleUsers = async (userData) => {
  const response = await api.delete(`/admin-users/delete-multiple`, {
    data: { userIds: userData },
  });
  return response.data;
};

const userService = {
  getUsersForAdmin,
  approveRejectMultipleUsers,
  blockUnblockMultipleUsers,
  deleteMultipleUsers,
};

export default userService;

// src/store/slices/userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  getUsersForAdminThunk,
  approveRejectMultipleUsersThunk,
  blockUnblockMultipleUsersThunk,
  deleteMultipleUsersThunk,
  clearUserError,
} from "../thunks/userThunks";

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearUserError, (state) => {
        state.error = null;
      })
      .addCase(getUsersForAdminThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersForAdminThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getUsersForAdminThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(approveRejectMultipleUsersThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveRejectMultipleUsersThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(approveRejectMultipleUsersThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(blockUnblockMultipleUsersThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(blockUnblockMultipleUsersThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(blockUnblockMultipleUsersThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteMultipleUsersThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMultipleUsersThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteMultipleUsersThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
